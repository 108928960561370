import React from 'react';
import styled from 'styled-components';

import Layout from 'components/Order/Layout';
import TitleName from 'components/UI/Header/TitleName';

import { md } from 'utils/breakpoints';

const Container = styled.div``;

const TitleBar = styled.div`
  font-size: 20px;
  font-weight: 700;
  color: #3b3516;
  margin-bottom: 16px;

  @media (max-width: ${md}) {
    display: none;
  }
`;

const Card = styled.div`
  background-color: #ffffff;
  border-radius: 8px;
  font-size: 16px;
  font-weight: 400;
  line-height: 23px;
  color: #000000;
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 12px 0;
`;

const BlockTitle = styled.div`
  font-weight: 500;
`;

const UlBlock = styled.div`
  font-size: 16px;
  font-weight: 400;
  color: #000000;
  display: flex;
  position: relative;
  padding-left: 20px;

  &::before {
    content: '．';
    position: absolute;
    top: 0;
    left: 0;
  }
`;

const Terms = (props) => {
  const pageTitle = '會員服務條款';

  return (
    <Container>
      <TitleName />
      <Layout {...props} pageTitle={pageTitle}>
        <Container>
          <TitleBar>{pageTitle}</TitleBar>
          <Card>
            <div>
              歡迎您使用家樂福線上購物網(以下簡稱「本網站」)的會員帳號至本網站購物。本網站係由『家福股份有限公司』(以下簡稱「本公司」)建置,本網站會員帳號係依據本服務條款提供服務(以下簡稱「本服務」)。所有申請使用會員帳號服務之使用者(以下簡稱會員),都應該詳細閱讀下列條款,這些條款訂立的目的,是為了保護會員服務的提供者以及所有會員的利益,並為服務提供依據,當您於本網站任一管道完成註冊手續、或繼續使用本網站所提供之任一服務時,即視為已知悉並同意本服務條款的所有約定並完全接受本服務現有與未來衍生之服務條款及項目。本網站所提供之服務、商品、行銷等之相關會員服務條款,如有任何調整、變更或修改,本網站將以您所註冊之帳號(即電子郵件信箱)寄發相關資訊,作為會員條款變更之通知生效;本網站亦將公佈於網站平台,如您於任何調整或修改後有疑慮或預計變更會員使用者,請您來電洽詢或通知本網站之客服人員,如您未表示意見,本網站於電子郵件寄發日起10日後,即視為您已同意接受調整或修改後的約定事項。若您是未滿二十歲的未成年人,應於您註冊會員帳號前,將本服務條款請您的父母或監護人閱讀、瞭解並同意本服務條款之所有內容及其後修改變更後,方得註冊及使用本網站所提供的服務。當您使用或繼續使用本網站所提供之任一服務時,即表示您的父母或監護人已閱讀、瞭解並同意接受本服務條款之所有內容及其後修改變更。
            </div>
            <BlockTitle>壹:註冊、服務及權利義務</BlockTitle>
            <UlBlock>
              當您於本網站申請加入,並成功完成註冊後,即可享受本網站各項線上服務、優惠與權益,相關內容如下:
            </UlBlock>
            <div>
              <div>1. 商品預購服務</div>
              <div>2. 預購優惠回饋</div>
              <div>3. 會員權益通知</div>
              <div>4. 行銷資訊提供</div>
            </div>
            <div>
              <UlBlock>
                為維護會員權益,如系統產生登載異常情況時,本公司將暫停該會員行使線上各項服務功能;異常狀況解除時,本公司即恢復該會員使用各項服務功能。
              </UlBlock>
              <UlBlock>
                當您使用本網站各項服務內容時,以本網站及相關網站之交易資料為基準。
              </UlBlock>
              <UlBlock>
                請您每次連線完畢後均確實登出以結束您的會員帳號使用。
              </UlBlock>
              <UlBlock>
                您的會員帳號、密碼及會員權益均僅供您個人使用及享有,切勿轉借、轉讓他人或與他人合用。
              </UlBlock>
              <UlBlock>
                若您的會員帳號及密碼遭盜用、不當使用或其他致使本公司及合作會員網無法辯識是否為本人親自使用之情況時,本公司及合作會員網站對此所致之損害,概不負責。
              </UlBlock>
              <UlBlock>
                本公司得依實際情形,增加、修改或終止相關服務服務。
              </UlBlock>
              <UlBlock>
                本公司得依符合法令規定前提下,不定期更新或變更相關服務資訊,相關資訊以更新後為準,本公司將以電子郵件、電話、型錄、通信網路、網路公告或其他適當方式通知消費者。
              </UlBlock>
              <UlBlock>
                本網站及本公司為提供服務之必要通知會員相關訊息時,得以其所留存之任一聯繫方式為之,您的聯繫資料如有異動應隨時以登錄本網站、電話通知客服中心等方式進行資料更新,維持資料之正確性、即時性及完整性,若因您的資料錯誤、過期或其他非可歸責本公司的原因,導致本公司送達的訊息無法接受,仍視為本公司業已完成該通知的送達,如因而產生不利益則由會員自身負擔。
              </UlBlock>
            </div>
          </Card>
        </Container>
      </Layout>
    </Container>
  );
};

export default Terms;
